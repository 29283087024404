<template>
  <div>
    <div class="card">
      <div class="card-body">
        <a-page-header
          :title="$t('liste.listeElevesArchive')"
          :sub-title="$t('liste.consulterProfil')"
          @back="() => $router.go(-1)"
        >
          <template slot="tags">
            <a-tag color="red"> ARCHIVE </a-tag>
          </template>
          <template slot="extra">
            <a-button key="1" @click="generatePdf">
              <a-icon type="file-pdf" @click="generatePdf" />
              {{ $t("action.imprimerTab") }}
            </a-button>
          </template>
          <a-row type="flex">
            <a-statistic
              :title="$t('liste.total')"
              :value="filtredTable.length"
            />
            <a-statistic
              :title="$t('profil.fille')"
              prefix="%"
              :value="countFille"
              :style="{
                margin: '0 32px',
              }"
            />
            <a-statistic
              :title="$t('profil.garcon')"
              prefix="%"
              :value="countGarcon"
            />
          </a-row>
        </a-page-header>
        <div></div>
        <div>
          <a-table
            @change="tableChanged"
            :loading="tableLeading"
            :pagination="true"
            :data-source="data"
            :columns="columns"
            :rowKey="'_id'"
          >
            <template slot="firstName" slot-scope="text, record">
              <div key="firstName" @click="() => openProfile(record.id)">
                <a-input
                  v-if="record.editable"
                  style="margin: -5px 0"
                  :value="text"
                  @change="(e) => handleChange(e.target.value, record.id, col)"
                />
                <template v-else>
                  <a-avatar
                    size="large"
                    v-if="record.photo"
                    :src="settings.base_url + '/' + record.photo"
                  />
                  <a-avatar
                    size="large"
                    style="color: #f56a00; background-color: #fde3cf"
                    v-else
                  >
                    {{ record.firstName[0].toUpperCase() }}
                  </a-avatar>
                  <a-button size="small" type="link" class="ml-2">
                    {{ text + " " + record.lastName }}
                  </a-button>
                </template>
              </div>
            </template>
            <template
              v-for="col in ['ficheparent', 'attestationPresence']"
              :slot="col"
              slot-scope="text, record"
            >
              <div :key="col">
                <a-input
                  v-if="record.editable"
                  style="margin: -5px 0"
                  :value="text"
                  @change="(e) => handleChange(e.target.value, record.id, col)"
                />
                <template v-else>{{ text }}</template>
              </div>
            </template>
            <span slot="scolarite" slot-scope="text">
              <a-tag
                :key="text"
                :color="
                  text === 'NON INSCRIT' || text === 'غير مسجل'
                    ? 'volcano'
                    : 'green'
                "
              >
                {{ text.toUpperCase() }}
              </a-tag>
            </span>
            <a-button
              slot="ficheparent"
              slot-scope="text, record"
              @click="() => parentFile(record)"
            >
              <a-icon type="select" /> {{ $t("action.afficher") }}
            </a-button>
            <div
              slot="filterDropdown"
              slot-scope="{
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
                column,
              }"
              style="padding: 8px"
            >
              <a-input
                v-ant-ref="(c) => (searchInput = c)"
                :placeholder="`${$t('personnel.chercher')} ${column.title}`"
                :value="selectedKeys[0]"
                style="width: 188px; margin-bottom: 8px; display: block"
                @change="
                  (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
                "
                @pressEnter="
                  () => handleSearch(selectedKeys, confirm, column.dataIndex)
                "
              />
              <a-button
                type="primary"
                icon="search"
                size="small"
                style="width: 90px; margin-right: 8px"
                @click="
                  () => handleSearch(selectedKeys, confirm, column.dataIndex)
                "
                >{{ $t("paiement.chercher") }}</a-button
              >
              <a-button
                size="small"
                style="width: 90px"
                @click="() => handleReset(clearFilters)"
                >{{ $t("action.reinitialiser") }}</a-button
              >
            </div>
            <a-icon
              slot="filterIcon"
              slot-scope="filtered"
              type="search"
              :style="{ color: filtered ? '#108ee9' : undefined }"
            />
            <template
              slot="customRender"
              slot-scope="text, record, index, column"
            >
              <span v-if="searchText && searchedColumn === column.dataIndex">
                <template
                  v-for="(fragment, i) in text
                    .toString()
                    .split(
                      new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i')
                    )"
                >
                  <mark
                    v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                    :key="i"
                    class="highlight"
                    >{{ fragment }}</mark
                  >
                  <template v-else>{{ fragment }}</template>
                </template>
              </span>
              <template v-else>{{ text }}</template>
            </template>
            <template slot="actions" slot-scope="text, record">
              <a-button-group>
                <a-popconfirm
                  :title="$t('all.sureTo')"
                  @confirm="() => reactive(record.id)"
                  v-if="verifyAccess(['all', 'suppression_eleve'])"
                >
                  <a-button icon="plus">
                    {{ $t("liste.reactive") }}
                  </a-button>
                </a-popconfirm>
                <a-button v-else icon="plus">
                  {{ $t("liste.reactive") }}
                </a-button>
              </a-button-group>
            </template>
          </a-table>
        </div>
        <a-modal
          v-model="visibleParentFile"
          :title="$t('all.enterInformationBelow')"
          :width="1000"
          :key="componentKey"
        >
          <template slot="footer">
            <a-button key="back" @click="handleCancelModalParent">
              {{ $t("action.annuler") }}
            </a-button>
            <a-button
              key="submit"
              type="primary"
              :loading="loadingData"
              :disabled="true"
              @click="updateParent"
            >
              {{ $t("action.confirmer") }}
            </a-button>
          </template>
          <div>
            <span class="span">
              <label>
                {{ $t("liste.nomPere") }}
                <a-input
                  id="nomPere"
                  type="text"
                  name="nomPere"
                  :default-value="activeParent.father.firstName"
                />
              </label>
              <label>
                {{ $t("liste.prenomPere") }}
                <a-input
                  id="prenomPere"
                  type="text"
                  name="prenomPere"
                  :default-value="activeParent.father.lastName"
                />
              </label>
            </span>
            <span class="span">
              <label class="space">
                {{ $t("liste.nomMere") }}
                <a-input
                  id="nomMere"
                  class="inp"
                  type="text"
                  name="nomMere"
                  :default-value="activeParent.mother.firstName"
                />
              </label>
              <label class="space">
                {{ $t("liste.prenomMere") }}
                <a-input
                  id="prenomMere"
                  class="inp"
                  type="text"
                  name="prenomMere"
                  :default-value="activeParent.mother.lastName"
                />
              </label>
            </span>
            <span class="span">
              <label>
                {{ $t("liste.telPere") }}
                <a-input
                  id="telephonePere"
                  type="text"
                  name="telephonePere"
                  :default-value="activeParent.father.phone"
                />
              </label>
              <label class="space">
                {{ $t("liste.telMere") }}
                <a-input
                  id="telephoneMere"
                  class="inp"
                  type="text"
                  name="telephoneMere"
                  :default-value="activeParent.mother.phone"
                />
              </label>
            </span>
            <span class="span">
              <label>
                {{ $t("liste.metierPere") }}
                <a-input
                  id="FonctionPere"
                  type="text"
                  name="FonctionPere"
                  :default-value="activeParent.father.job"
                />
              </label>
              <label class="space">
                {{ $t("liste.metierMere") }}
                <a-input
                  id="FonctionMere"
                  class="inp"
                  type="text"
                  name="FonctionMere"
                  :default-value="activeParent.mother.job"
                />
              </label>
            </span>
            <span class="span">
              <label>
                {{ $t("liste.emailParents") }}
                <a-input
                  id="emailParent"
                  type="text"
                  name="emailParent"
                  :default-value="activeParent.email"
                />
              </label>
              <label class="space">
                {{ $t("liste.adresseParents") }}
                <a-input
                  id="adressParent"
                  class="inp"
                  type="text"
                  name="adressParent"
                  :default-value="activeParent.adress"
                />
              </label>
            </span>
          </div>
        </a-modal>

        <a-modal
          v-model="visibleAttestationPresence"
          :title="$t('all.enterInformationBelow')"
          :width="500"
          :key="componentKey"
        >
          <template slot="footer">
            <a-button
              key="back"
              @click="() => (this.visibleAttestationPresence = false)"
            >
              {{ $t("action.annuler") }}
            </a-button>
            <a-button
              type="primary"
              :loading="loadingData"
              :disabled="loadingData"
              @click="attestationPresencePDF(activeStudent)"
            >
              <img
                style="border-radius: 30%; margin-right: 5px"
                width="20"
                src="resources/images/flags/tunisia.svg"
                alt="Arabic"
              />
              {{ $t("action.imprimer") }}
            </a-button>
            <a-button
              type="primary"
              :loading="loadingData"
              :disabled="loadingData"
              @click="attestationPresenceFr(activeStudent)"
            >
              <img
                style="border-radius: 30%; margin-right: 5px"
                width="20"
                src="resources/images/flags/france.svg"
                alt="Arabic"
              />
              {{ $t("action.imprimer") }}
            </a-button>
          </template>
          <div>
            <span class="span">
              <label>
                {{ $t("liste.regionalEducation") }}
                <a-input
                  id="cr"
                  type="text"
                  name="cr"
                  :default-value="schoolDetails.delegeRegional"
                />
              </label>
            </span>
            <span class="span">
              <label>
                {{ $t("etablissement.directrice") }}
                <a-input
                  id="dir"
                  type="text"
                  name="dir"
                  :default-value="schoolDetails.headMaster"
                />
              </label>
            </span>
            <span class="span">
              <label>
                {{ $t("bulletin.nom") }}
                <a-input
                  id="nomStudent"
                  type="text"
                  name="nomStudent"
                  :default-value="
                    activeStudent.firstName + ' ' + activeStudent.lastName
                  "
                />
              </label>
            </span>
            <span class="span">
              <label>
                {{ $t("profil.dateNaissance") }}
                <a-form-item>
                  <a-date-picker
                    :placeholder="$t('profil.dateNaissance')"
                    format="DD-MM-YYYY"
                    v-model="attestationPresenceBirthdate"
                    v-decorator="[
                      'BirthDate',
                      {
                        rules: [
                          {
                            required: true,
                            message: $t('requis.dateNaissance'),
                          },
                        ],
                      },
                    ]"
                  />
                </a-form-item>
              </label>
            </span>
            <span class="span">
              <label>
                {{ $t("profil.classe") }}
                <a-input
                  id="className"
                  type="text"
                  name="className"
                  :default-value="activeStudent.scolarite"
                />
              </label>
            </span>

            <span class="span">
              <label>
                {{ $t("punition.raison") }}

                <a-input
                  :placeholder="$t('punition.raison')"
                  id="reason"
                  name="reason"
                  type="textarea"
                  default-value="........................................"
                  v-decorator="[
                    'reason',
                    {
                      trigger: ``,
                    },
                  ]"
                />
              </label>
            </span>

            <span class="span">
              <label>
                {{ $t("liste.recuEn") }}
                <a-input
                  id="recuEn"
                  type="text"
                  name="recuEn"
                  default-value="...................."
                />
              </label>
            </span>
          </div>
        </a-modal>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import router from "@/router";
import $ from "jquery";
import apiClient from "@/services/axios";
import JsPDF from "jspdf";
import { mapState } from "vuex";
import "jspdf-autotable";
import { notification } from "ant-design-vue";
import _ from "lodash";
import moment from "moment";

import axios from "axios";

const { AwesomeQR } = require("awesome-qr");

function readbleDate(dateString) {
  const date = new Date(dateString);
  const dd = String(date.getDate()).padStart(2, "0");
  const mm = String(date.getMonth() + 1).padStart(2, "0"); //January is 0!
  const yyyy = date.getFullYear();
  return mm + "/" + dd + "/" + yyyy;
}
function parentFormater(parent) {
  return {
    name:
      parent.father.firstName +
      " " +
      parent.father.lastName +
      " et " +
      parent.mother.firstName +
      " " +
      parent.mother.lastName,
    id: parent._id,
  };
}

export default {
  created() {
    this.$watch(
      () => this.settings.schoolarYears,
      (newValue, oldValue) => {
        this.init();
      }
    );
    try {
      this.init();
    } catch (error) {
      console.error(
        "Failed on load ,error handling strategy...",
        error.message
      );
    }
  },
  data() {
    return {
      visibleDeleteReasson: false,
      deleteReason: "",
      schoolDetails: {},
      loadingBadges: false,
      componentKey: 0,
      disabledSP: false,
      activeBuilding: {},
      filtredTable: [],
      tableLeading: true,
      reductions: false,
      newStudent: {
        birthDate: null,
        gender: null,
        classRoom: null,
        parent: null,
      },
      classRoomList: [],
      newParent: false,
      addNewParent: false,
      parentsList: [],
      parentsCount: 0,
      activeParentChange: {},
      activeStudent: {},
      attestationPresenceBirthdate: "",
      activeParent: {
        father: {
          firstname: "",
          lastName: "",
          job: "",
          email: "",
        },
        mother: {
          firstname: "",
          lastName: "",
          job: "",
          email: "",
        },
        students: [
          {
            firstName: "",
            lastName: "",
            _id: "",
          },
        ],
      },
      activeStudentlist: [],
      editingKey: "",
      visibleParentFile: false,
      visibleAttestationPresence: false,
      data: [],
      cacheData: null,
      searchText: "",
      searchInput: null,
      searchedColumn: "",
      targetKeys: [],
      currentYear: "",
      value: 1,
      value1: 1,
      loadingData: false,
      visibleData: false,
      columns: [
        {
          title: this.$t("liste.nomPrenom"),
          dataIndex: "firstName",
          key: "firstName",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "firstName",
          },
          onFilter: (value, record) =>
            record.firstName.toLowerCase().includes(value.toLowerCase()) ||
            record.lastName.toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: this.$t("liste.scolarite"),
          dataIndex: "scolarite",
          key: "scolarite",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "scolarite",
          },
          onFilter: (value, record) =>
            record.scolarite.toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: this.$t("liste.ficheParent"),
          dataIndex: "ficheparent",
          key: "parent",
          scopedSlots: {
            customRender: "ficheparent",
          },
        },
        {
          title: this.$t("action.actions"),
          dataIndex: "actions",
          scopedSlots: { customRender: "actions" },
          fixed: "right",
        },
      ],
    };
  },
  computed: {
    ...mapState(["settings", "user"]),
    countGarcon: function () {
      return (
        parseFloat(
          (
            (this.filtredTable.reduce(
              (a, v) => (v.gender === "male" ? a + 1 : a),
              0
            ) *
              100) /
            this.filtredTable.length
          ).toFixed(1)
        ) || 0
      );
    },
    countFille: function () {
      return (
        parseFloat(
          (
            (this.filtredTable.reduce(
              (a, v) => (v.gender === "female" ? a + 1 : a),
              0
            ) *
              100) /
            this.filtredTable.length
          ).toFixed(1)
        ) || 0
      );
    },
  },
  methods: {
    async init() {
      this.currentYear = this.settings.schoolarYears.find(
        (sc) => sc._id == this.settings.activeSchoolarYear
      ).name;
      this.activeBuilding = this.settings.image.find(
        (building) => building.dbName == this.settings.activeBuilding
      );
      this.schoolDetails = this.user.building.find(
        (el) => el.dbName == this.settings.activeBuilding
      );
      this.tableLeading = true;
      apiClient
        .post("/classrooms/filter", {
          query: {
            status: "inactive",
          },
          aggregation: {
            $project: {
              _id: 1,
              name: 1,
            },
          },
        })
        .then((res) => {
          this.classRoomList = res.data.map((item) => ({
            name: item.name,
            id: item._id,
          }));
        });
      apiClient
        .post("/parents/filter", {
          query: {},
          aggregation: {
            $project: {
              father: { firstName: 1, lastName: 1 },
              mother: { firstName: 1, lastName: 1 },
              status: 1,
            },
          },
        })
        .then((res) => {
          this.parentsList = res.data.map((item) => parentFormater(item));
          this.parentsCount = res.data.filter(function (item) {
            return item.status === "active" || !item.status;
          }).length;
        });
      apiClient
        .post("/students/filter", {
          query: {
            status: "inactive",
          },
          aggregation: [
            {
              $set: {
                classRoom: {
                  $convert: {
                    input: `$schoolarYearsHistory.${this.settings.activeSchoolarYear}`,
                    to: "objectId",
                    onError: null,
                    onNull: null,
                  },
                },
              },
            },
            {
              $lookup: {
                from: "parents",
                localField: "parent",
                foreignField: "_id",
                as: "parent",
              },
            },
            {
              $lookup: {
                from: "classrooms",
                localField: "classRoom",
                foreignField: "_id",
                as: "classRoom",
              },
            },
            {
              $project: {
                _id: 1,
                parent: {
                  father: { $arrayElemAt: ["$parent.father", 0] },
                  mother: { $arrayElemAt: ["$parent.mother", 0] },
                  _id: { $arrayElemAt: ["$parent._id", 0] },
                  adress: 1,
                  email: 1,
                  students: 1,
                },
                classRoom: { $arrayElemAt: ["$classRoom.name", 0] },
                BirthDate: 1,
                firstName: 1,
                lastName: 1,
                gender: 1,
                photo: 1,
                adress: 1,
                uniqueId: 1,
                schoolarYearsHistory: 1,
              },
            },
          ],
        })
        .then((res) => {
          this.data = res.data.map((item) => this.studentFormater(item));

          this.data.sort((a, b) => {
            let fullname_a = a.firstName + a.lastName;
            let fullname_b = b.firstName + b.lastName;
            return fullname_a.localeCompare(fullname_b);
          });

          this.cacheData = this.data;
          this.tableLeading = false;
          this.filtredTable = this.data;
        });
    },
    verifyAccess: function (accessRoles) {
      const existing = this.user.access;
      if (existing.all) return true;
      if (
        existing.liste_eleves === true ||
        (existing.liste_eleves &&
          existing.liste_eleves.length > 0 &&
          existing.liste_eleves.find((e) => accessRoles.includes(e)))
      )
        return true;
      else return false;
    },
    moment,
    debounceField: _.debounce(function (e) {
      let field = e.target.id;
      this.newStudentForm.setFieldsValue({
        [field]: e.target.value,
      });
      this.newStudentForm.validateFields([`${field}`]);
    }, 50),

    disabledDate(current) {
      // Can not select days before today and today
      return current && current > moment().endOf("day");
    },
    tableChanged(pagination, filters, sorter, extra) {
      this.filtredTable = extra.currentDataSource;
    },
    openProfile(id) {
      router.push(`/eleve/${id}`).catch((error) => {
        console.info(error);
      });
    },
    studentFormater(student) {
      var modified = student;
      modified.BirthDate = readbleDate(modified.BirthDate);
      modified.createdAt = readbleDate(modified.createdAt);
      modified.id = student._id;
      return {
        ...modified,
        scolarite: student.classRoom
          ? student.classRoom
          : this.$t("liste.nonInscrit"),
      };
    },
    updateParent() {
      const id = this.activeParent._id;
      this.loadingData = true;
      const update = {
        father: {
          firstName: $("#nomPere").val(),
          lastName: $("#prenomPere").val(),
          job: $("#ProfessionPere").val(),
          phone: $("#telephonePere").val(),
        },
        mother: {
          firstName: $("#nomMere").val(),
          lastName: $("#prenomMere").val(),
          job: $("#professionMere").val(),
          phone: $("#telephoneMere").val(),
        },
        email: $("#emailParent").val(),
        adress: $("#adressParent").val(),
      };
      apiClient
        .patch(`/parents/${id}`, { data: update })
        .then((res) => {
          this.data = this.data.map((student) =>
            student.parent._id == id
              ? {
                  ...student,
                  parent: {
                    ...student.parent,
                    ...update,
                  },
                }
              : student
          );
          this.parentsList = this.parentsList.map((parent) =>
            parent.id == id ? parentFormater({ ...update, _id: id }) : parent
          );
          this.visibleParentFile = false;
          this.loadingData = false;
          location.reload();
        })
        .catch(() => {
          this.visibleParentFile = false;
          this.loadingData = false;
          this.openNotificationWithIcon(
            "warning",
            this.$t("changementNonEffectue"),
            this.$t("champsVides")
          );
        });
    },
    triggerAddNewParent() {
      this.disabledSP = !this.disabledSP;
      this.newParent = !this.newParent;
    },
    handleNewStudentParentOnChange(value) {
      this.newStudent.parent = value;
    },
    handleNewStudentDateOnChange(value) {
      this.newStudent.birthDate = value;
    },
    handleNewStudentClassRoomOnChange(value) {
      this.newStudent.classRoom = value;
    },
    handleBlur() {},
    handleFocus() {},
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    parentFile(id) {
      this.componentKey += 1;
      this.activeParent = JSON.parse(JSON.stringify(id.parent[0]));

      this.visibleParentFile = true;
    },

    handleChange(value, id, column) {
      const newData = [...this.data];
      const target = newData.filter((item) => id === item.id)[0];
      if (target) {
        target[column] = value;
        this.data = newData;
      }
    },
    edit(id) {
      const newData = [...this.data];
      const target = newData.filter((item) => id === item.id)[0];
      this.editingKey = id;
      if (target) {
        target.editable = true;
        this.data = newData;
        this.cacheData = this.data;
      }
    },

    handleCancelModalAdd(e) {
      this.visibleData = false;
      this.addNewParent = false;
    },
    handleCancelModalParent(e) {
      this.visibleParentFile = false;
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm();
      this.searchText = selectedKeys[0];
      this.searchedColumn = dataIndex;
    },
    handleReset(clearFilters) {
      clearFilters();
      this.searchText = "";
    },
    handleChangeTransfer(targetKeys, direction, moveKeys) {
      this.targetKeys = targetKeys;
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },

    async drawBadge(doc, x, y, imgData, logo, student, pageNumber) {
      doc.setPage(pageNumber);
      doc.setFillColor(0, 0, 0);
      doc.rect(4 + x, 4 + y, 84, 48, "S");
      var today = new Date();
      const options = {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      };
      doc.setFont("Amiri");
      doc.setFontSize(10);
      let birthDay = new Date(student.BirthDate);
      doc.setFont("Amiri", "Bold");
      doc.text(52 + x, 21 + y, "Nom: ");
      doc.setFont("Amiri", "normal");
      doc.text(62 + x, 21 + y, this.capitalizeFirstLetter(student.lastName));
      doc.setFont("Amiri", "Bold");
      doc.text(52 + x, 27 + y, "Prénom: ");
      doc.setFont("Amiri", "normal");
      doc.text(67 + x, 27 + y, this.capitalizeFirstLetter(student.firstName));
      doc.setFont("Amiri", "Bold");
      doc.text(52 + x, 33 + y, "Date de naissance:");
      doc.setFont("Amiri", "normal");
      doc.text(52 + x, 39 + y, birthDay.toLocaleDateString("fr-FR", options));
      doc.setFontSize(5);
      doc.text(
        52 + x,
        49 + y,
        "Géneré le : " + " " + today.toLocaleDateString("fr-FR", options)
      );

      doc.addImage(imgData, "JPEG", 75 + x, 5 + y, 10, 10);

      let qrDataDetail =
        student._id +
        "." +
        this.settings.activeBuilding +
        "." +
        this.settings.activeSchoolarYear +
        "." +
        this.settings.base_url;

      const qrData = btoa(qrDataDetail);

      await new AwesomeQR({
        text: qrData,
        logoImage: logo,
        size: 200,
        correctLevel: 1,
        logoScale: 0.2,
        logoMargin: 0,
        logoCornerRadius: 8,
        whiteMargin: true,
        dotScale: 1,
        autoColor: true,
        binarize: false,
        binarizeThreshold: 128,
        bindElement: true,
        backgroundColor: "#FFFFFF",
      })
        .draw()
        .then((dataURL) => {
          doc.setPage(pageNumber);
          doc.addImage(dataURL, "JPEG", 5 + x, 5 + y, 45, 45);
        });
    },
    async generateBadges() {
      this.loadingBadges = true;
      const doc = new JsPDF();
      const pdf_height = doc.internal.pageSize.height;
      const pdf_width = doc.internal.pageSize.width;

      doc.setFont("Amiri");

      let arrayOfImages = this.settings.image;
      for (var i = 0; i < arrayOfImages.length; i++) {
        if (this.settings.activeBuilding === arrayOfImages[i].db)
          var imgSrc = arrayOfImages[i].logo;
      }

      let imgData = "data:image/jpeg;base64, ";

      await axios
        .get(imgSrc, {
          responseType: "arraybuffer",
        })
        .then((response) => {
          imgData += Buffer.from(response.data, "binary").toString("base64");
        });

      const data = {};
      let logo = "data:image/jpeg;base64, ";

      await axios
        .get("favicon.png", {
          responseType: "arraybuffer",
        })
        .then((response) => {
          logo += Buffer.from(response.data, "binary").toString("base64");
        });

      this.filtredTable.forEach((student) => {
        if (!data[student.classRoom]) data[student.classRoom] = [];
        data[student.classRoom].push(student);
      });

      let y = 0;
      let left = true;
      let firstPage = true;
      let currentPage = 1;

      // array for optimization
      const promises = [];

      for (const classroom of Object.keys(data)) {
        doc.setFontSize(17);
        y = 15;
        if (!firstPage) {
          doc.addPage();
          currentPage++;
          left = true;
        }
        const width = doc.getTextWidth(classroom);
        doc.text(classroom, pdf_width / 2 - width / 2, 10);
        const classroomData = data[classroom];
        for (let i = 0; i < classroomData.length; i++) {
          if (left)
            promises.push(
              this.drawBadge(
                doc,
                16,
                y,
                imgData,
                logo,
                classroomData[i],
                currentPage
              )
            );
          else
            promises.push(
              this.drawBadge(
                doc,
                100,
                y,
                imgData,
                logo,
                classroomData[i],
                currentPage
              )
            );

          if (!left) {
            y += 48.1;
            if (y + 68.1 >= pdf_height) {
              y = 0;
              left = false;
              doc.addPage();
              currentPage++;
            }
          }

          left = !left;
        }

        firstPage = false;
      }

      await Promise.all(promises)
        .then((values) => {
          //page numbering
          const pages = doc.internal.getNumberOfPages();
          const pageWidth = doc.internal.pageSize.width;
          const pageHeight = doc.internal.pageSize.height;
          doc.setFontSize(8); //Optional

          for (let j = 1; j < pages + 1; j++) {
            let horizontalPos = pageWidth / 2;
            let verticalPos = pageHeight - 5;
            doc.setPage(j);
            doc.text(`Page ${j} / ${pages}`, horizontalPos, verticalPos, {
              align: "center",
            });
          }

          this.loadingBadges = false;
          doc.save("Liste des badges.pdf");
        })
        .catch((reason) => {
          console.log(reason);
        });
    },
    generatePdf() {
      this.$gtag.event("Imp tab listeElèves archive", {
        event_category: "Impression PDF",
        event_label: "ListeElèves archivés section",
        value: 1,
      });

      let arrayOfImages = this.settings.image;
      for (var i = 0; i < arrayOfImages.length; i++) {
        if (this.settings.activeBuilding === arrayOfImages[i].db) {
          var imgData = new Image();
          imgData.src = arrayOfImages[i].logo;
        }
      }
      const doc = new JsPDF();
      doc.setFont("Amiri");
      var date = new Date();
      var titre = "Liste des élèves archivé";
      const options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      doc.setFontSize(15);
      doc.text(80, 65, titre);
      doc.setFontSize(10);
      doc.addImage(imgData, "JPEG", 8, 8, 40, 40);
      doc.setFontSize(12);
      doc.text(150, 40, date.toLocaleDateString("fr-FR", options));
      var arr = [];
      const array = [];
      for (let i = 0; i < this.filtredTable.length; i++) {
        arr.push(
          this.filtredTable[i].uniqueId ? this.filtredTable[i].uniqueId : "--"
        );

        arr.push(this.filtredTable[i].firstName);
        arr.push(this.filtredTable[i].lastName);
        arr.push(this.filtredTable[i].scolarite);
        arr.push(this.filtredTable[i].adress);
        array.push(arr);
        arr = [];
      }
      doc.autoTable({
        theme: "grid",
        styles: { font: "Amiri" },
        margin: { top: 70 },
        head: [["Identifiant unique", "Prénon", "Nom", "scolarité", "Adress"]],
        body: array,
        foot: [
          [
            { colSpan: 3, content: "Total", styles: { halign: "center" } },
            {
              colSpan: 2,
              content: this.filtredTable.length,
              styles: { halign: "center" },
            },
          ],
        ],
      });
      //page numbering
      const pages = doc.internal.getNumberOfPages();
      const pageWidth = doc.internal.pageSize.width;
      const pageHeight = doc.internal.pageSize.height;
      doc.setFontSize(8); //Optional

      for (let j = 1; j < pages + 1; j++) {
        let horizontalPos = pageWidth / 2;
        let verticalPos = pageHeight - 10;
        doc.setPage(j);
        doc.text(`Page ${j} / ${pages}`, horizontalPos, verticalPos, {
          align: "center",
        });
      }
      //end page numbering
      doc.save("Liste_des_élèves_Archivé.pdf");
    },
    waitLoadingImage(image) {
      return new Promise((res, rej) => {
        image.onload = (t) => {
          res();
        };
        image.onerror = (e) => {
          rej();
        };
      });
    },

    attestationReussitePDF(record) {
      let arrayOfImages = this.settings.image;
      for (var i = 0; i < arrayOfImages.length; i++) {
        if (this.settings.activeBuilding === arrayOfImages[i].db) {
          var imgData = new Image();
          imgData.src = arrayOfImages[i].logo;
        }
      }
      const doc = new JsPDF();
      doc.setFont("Amiri");
      var date = new Date();
      var titre = "Attestation de réussite";
      const options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      doc.setFontSize(30);
      doc.text(titre, 55, 90);
      doc.setFontSize(10);
      doc.addImage(imgData, "JPEG", 8, 8, 40, 40);
      doc.setFontSize(12);
      doc.text(150, 40, date.toLocaleDateString("fr-FR", options));
      if (record) {
        var titre = "Attestation de réussite";
        var string = `Je soussigné ${
          this.activeBuilding.headMaster
        } le directeur de l'école ${
          this.activeBuilding.dbName
        }  \n \n atteste que l'élève ${
          record.firstName + " " + record.lastName
        } né(e) le ${
          record.BirthDate
        } est présent(e) \n \n au sein de notre etablissement pour l'année scolaire ${
          this.currentYear
        }.`;
        var signature = "Signature";
        doc.setFontSize(30);
        doc.text(titre, 55, 90);
        doc.setFontSize(15);
        doc.text(string, 20, 110);
        doc.setFontSize(15);
        doc.text(signature, 150, 165);
      }
      doc.save("attestation_réussite.pdf");
    },
    onChange(e) {
      this.newStudent.gender = e.target.value;
    },
    onChangeParent(e) {},
    openNotificationWithIcon(type, msg, desc) {
      notification[type]({
        message: msg,
        description: desc,
      });
    },

    reactive(id) {
      apiClient
        .post(`/students/${id}/activate`, {})
        .then((res) => {
          const dataSource = [...this.data];
          this.data = dataSource.filter((item) => item.id !== id);
          this.cacheData = this.data;
          this.openNotificationWithIcon(
            "success",
            this.$t("success.eleveRective")
          );
        })
        .catch((e) => this.$message.warning(this.$t("error.erreur")));
    },
  },
};
</script>
<style scoped>
.highlight {
  background-color: rgb(255, 192, 105);
  padding: 0px;
}
.editable-row-operations a {
  margin-right: 8px;
}
.switch {
  float: right;
  margin-right: 15px;
}
.bottom {
  margin-top: 7px;
}
label {
  width: 300px;
  line-height: 26px;
  margin-bottom: 20px;
}

a-input {
  height: 25px;
  width: 50%;
  margin-left: 50px;
}
.span {
  /* display: flex;  */
  flex-direction: row;
}
.space {
  margin-left: 200px;
}
.inp {
  margin-left: 0px;
}
.clearfix {
  float: right;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
